<template>
  <div style="color: rgb(55, 53, 47)">
    <MobileHeader v-if="isMobile"/>
    <BeeHeader v-else/>
    <LayoutArticle :class="isMobile ? 'mobile-view' : 'py-10'">
      <div class="max-w-prose mx-auto" :class="isMobile ? 'text-xs p-3' : 'text-lg'">
        <article id="cb27aa6f-b51c-493a-8816-c01c69d83757" class="page sans">
          <h1 class="page-title text-center">ĐIỀU KIỆN GIAO DỊCH CHUNG</h1>
          <div class="page-body">
            <p id="97c1a5ba-3402-4d0b-8e59-54805c2e6b30" class="">
              <strong>ĐIỀU KIỆN GIAO DỊCH CHUNG</strong>
            </p>
            <ol
              type="1"
              id="745f380c-d14b-4d66-9a3b-0920d9a888d2"
              class="numbered-list"
              start="1"
            >
              <li>
                <strong>Các điều khoản giao dịch trên Website Metric.vn:</strong>
              </li>
            </ol>
            <ol
              type="1"
              id="ac8ab601-917c-4645-8c7c-d718e4a305f1"
              class="numbered-list"
              start="2"
            >
              <li>
                <strong>Định nghĩa:</strong>
                <p id="77e945ff-e0b7-4924-b0fd-cfb8dc23d3af" class="">
                  <strong>Khách hàng/người mua/bạn:</strong> Là các cá nhân/tổ chức
                  tại Việt Nam, có nhu cầu mua phần mềm phân tích thị trường và quản
                  lý bán hang.
                </p>
              </li>
            </ol>
            <ol
              type="1"
              id="3ed3ef8c-04e8-4d86-845c-318f5a878613"
              class="numbered-list"
              start="3"
            >
              <li><strong>Điều khoản chung:</strong></li>
            </ol>
            <ul id="ea80cdc0-0143-4db6-9975-5246e842ea19" class="bulleted-list">
              <li style="list-style-type: disc">
                Website Metric.vn (hay Metric Ecom Việt) là kênh bán hàng online
                nhằm cung cấp dịch vụ phân tích một cách thuận tiện nhất.
              </li>
            </ul>
            <ul id="6d609f94-d959-4cbd-9a01-3ab7eb5bb634" class="bulleted-list">
              <li style="list-style-type: disc">
                Các bên cam kết không thực hiện các giao dịch không phù hợp với văn
                hóa, phong tập tập quán của Việt Nam, các giao dịch của các mặt hàng
                thuộc nhóm hàng cấm theo quy định của luật pháp quốc tế và luật pháp
                Việt Nam.
              </li>
            </ul>
            <ul id="0c4dfc4d-1c1a-4d03-ada4-68a4c22961b9" class="bulleted-list">
              <li style="list-style-type: disc">
                Người mua hiểu rõ quyền lợi, trách nhiệm và hoàn toàn tự nguyện khi
                đặt mua phần mềm tại Website Metric.vn.
              </li>
            </ul>
            <ul id="48352af4-45ac-4616-a206-e0221aa32db8" class="bulleted-list">
              <li style="list-style-type: disc">
                Mọi giao dịch thông qua Website Metric.vn được mặc định hiểu rằng
                quý bạn đã đọc và cam kết việc đồng ý theo chính sách này.
              </li>
            </ul>
            <p id="ce9625a5-5f3a-4963-ab30-c2190cb7ed43" class="">
              Quý bạn cần đọc rõ những điều khoản được quy định tại chính sách này
              để tránh những vấn đề ngộ nhận ngoài ý muốn.
            </p>
            <ol
              type="1"
              id="afffc1f5-45a9-4a16-9aa3-913a07278cef"
              class="numbered-list"
              start="1"
            >
              <li><strong>Trách nhiệm của các bên là gì?</strong></li>
            </ol>
            <ol
              type="1"
              id="bb46b726-364e-4213-ae26-874720d37a25"
              class="numbered-list"
              start="2"
            >
              <li><strong>Trách nhiệm của Website Metric.vn:</strong></li>
            </ol>
            <p id="24419967-f1ff-4008-a6b2-e7efe847195c" class="">
              Chính sách về trách nhiệm với hàng hóa thông qua các hoạt động giao
              dịch của Website Metric.vn được thực hiện theo quy định dưới đây:
            </p>
            <ul id="42e01dd7-1009-49c6-ba8d-ab6129572518" class="bulleted-list">
              <li style="list-style-type: disc">
                Bảo đảm quá trình giao dịch đặt hàng của bạn diễn ra an toàn.
              </li>
            </ul>
            <ul id="683fa12a-1a3a-4f71-9900-484a6a14f5ce" class="bulleted-list">
              <li style="list-style-type: disc">
                Theo dõi, cập nhật thông tin, tư vấn và hỗ trợ quý bạn để hoàn tất
                các thủ tục giúp việc giao dịch được thành công.
              </li>
            </ul>
            <ul id="726851d5-96e0-4018-bf6c-8dff037e75b4" class="bulleted-list">
              <li style="list-style-type: disc">
                Website Metric.vn cam kết việc hỗ trợ bạn xử lý các tình huống phát
                sinh trong và sau quá trình đặt mua hàng và lúc đã nhận hàng theo
                đúng chính sách của chúng tôi đề ra.
              </li>
            </ul>
            <ol
              type="1"
              id="224a47da-4cc3-4b15-9cf6-46455fe8b591"
              class="numbered-list"
              start="1"
            >
              <li><strong>Trách nhiệm của người mua:</strong></li>
            </ol>
            <ul id="7e1e4d38-efcd-46a5-ba31-f853dcaa2026" class="bulleted-list">
              <li style="list-style-type: disc">
                Người mua có trách nhiệm thanh toán toàn bộ tiền hàng theo niêm yết
                tại Website Website Metric.vn.
              </li>
            </ul>
            <ul id="fcbedbd7-a60d-4ce1-b110-137f38b2e65d" class="bulleted-list">
              <li style="list-style-type: disc">
                Cung cấp chính xác thông tin cá nhân, tổ chức đặt mua;
              </li>
            </ul>
            <ul id="17233617-17c6-4838-b651-80d1303ccc2f" class="bulleted-list">
              <li style="list-style-type: disc">
                Tìm hiểu kỹ thông tin chi tiết sản phẩm trước khi đặt mua hàng như:
                Giá thành, thời hạn sử dụng… dịch vụ trước khi đặt hàng.
              </li>
            </ul>
            <ul id="a1c9dfa1-d881-4294-8349-45253de719bf" class="bulleted-list">
              <li style="list-style-type: disc">
                Trách nhiệm về chất lượng hàng khi mua tại Website Metric.vn thuộc
                về Website Metric.vn. Bạn cần đọc kỹ nội dung mô tả của sản phẩm.
              </li>
            </ul>
            <ol
              type="1"
              id="fb1d1170-38de-48a3-98d1-75ab48e2046b"
              class="numbered-list"
              start="1"
            >
              <li>
                <strong>Điều khoản sử dụng dịch vụ dành cho khách hàng:</strong>
              </li>
            </ol>
            <p id="32c3eb04-1fc2-4b62-833b-a43b04e9d2c1" class="">
              <strong>1. Chấp thuận:</strong> Khi khách hàng đăng ký một tài khoản
              trên Website Metric.vn đồng nghĩa với việc khách đồng ý với Điều kiện
              giao dịch chung này.
            </p>
            <p id="d6ec6ff4-9c88-4b9f-a957-366b57391ce9" class="">
              <strong>2. Đặt hàng:</strong> Để đặt hàng, sử dụng dịch vụ của Website
              Metric.vn, Khách hàng cần phải cung cấp cho Website Metric.vn: Tên;
              địa chỉ email, số điện thoại; địa chỉ liên lạc có giá trị. Khách hàng
              xác thực rằng các thông tin mà Khách hàng cung cấp là chính xác, và sẽ
              cập nhật lại thông tin một cách nhanh chóng nếu có bất cứ sự thay đổi
              nào. Chúng tôi có quyền hủy bất cứ đơn đặt hàng nào mà không được xác
              nhận thông tin hoặc nghi ngờ bị giả mạo hoặc sử dụng cho các mục đích
              lừa đảo, hoặc nếu chúng tôi phát hiện ra thông tin mà Bạn cung cấp là
              không chính xác.
            </p>
            <p id="c5f02181-6265-44b0-a472-71d9c3880913" class="">
              <strong>3. Bảo mật:</strong>.Bạn có trách nhiệm đối với mọi hoạt động
              trên Website Metric.vn được thực hiện bởi chính bạn hoặc có sự đồng ý
              của bạn. Nếu Bạn nhận thấy hoặc nghi ngờ bất cứ việc sử dụng thông tin
              của bạn là trái phép, thông tin bị tiết lộ hoặc bị trộm hoặc có bất cứ
              vi phạm bảo mật nào, Bạn cần nhanh chóng thông báo cho Website
              Metric.vn và chúng tôi sẽ ngay lập tức xóa đơn hàng.
            </p>
            <p id="89e17d9a-19d6-47fa-8edc-b39cee9b87a8" class="">
              <strong>5. Bảo đảm và Tuyên bố của Bạn:</strong> Bạn bảo đảm và tuyên
              bố rằng: Bạn đã đủ 18 tuổi hoặc có sự giám sát của Người giám hộ Bạn
              được cho phép để truy cập và thực hiện thỏa thuận này, và Bạn chỉ sử
              dụng dịch vụ của Website Metric.vn cho các mục đích hợp pháp và phù
              hợp với các điều khoản này.
            </p>
            <p id="054b9c65-4510-4282-a4f1-7df512907a5b" class="">
              <strong>6. Bảo vệ dữ liệu cá nhân:</strong> Để cung cấp các dịch vụ
              chất lượng tốt tới Bạn, chúng tôi thu thập các thông tin cá nhân nhất
              định của Bạn, bao gồm nhưng không giới hạn tên, địa chỉ, địa chỉ email
              và các số điện thoại. Website của chúng tôi cũng sử dụng cookies và
              các công nghệ tự động tương tự để thu thập thông tin với mục đích mở
              rộng và cá biệt hóa trải nghiệm của Bạn. Các hoạt động thông tin của
              chúng tôi tuân thủ theo Chính sách Bảo mật của Website Metric.vn. Bạn
              chấp nhận rằng các thông tin Bạn cung cấp khi đặt hàng trên Website
              Metric.vn và khi thực hiện giao dịch trên Website Metric.vn có thể
              được di chuyển, lưu trữ và/hoặc xử lý tại các quốc gia khác mà việc đó
              là cần thiết để Website Metric.vn cung cấp dịch vụ đến Bạn. Nếu Bạn từ
              chối việc di chuyển, lưu trữ và/hoặc xử lý thông tin theo cách này, từ
              chối sử dụng cookies hoặc không đồng ý với Chính sách Bảo mật của
              chúng tôi, vui lòng không sử dụng Dịch vụ của Website Metric.vn
            </p>
            <p id="18f7ba3d-0e82-4fa1-ba08-0ab9c7637152" class="">
              <strong>7. Việc sử dụng Website của Bạn:</strong> Bạn không thể sử
              dụng Website và các dịch vụ của Website Metric.vn cho bất cứ mục đích
              bất hợp pháp nào. Chúng tôi có quyền đình chỉ, hạn chế quyền truy cập
              vào Website cũng như việc sử dụng các dịch vụ Website Metric.vn nếu
              theo ý kiến của chúng tôi, việc sử dụng của Bạn có thể đang vi phạm
              pháp luật, xâm phạm quyền lợi của những người khác hoặc vi phạm các
              điều khoản của thỏa thuận này. Bạn chỉ sử dụng Website và các dịch vụ
              của Website Metric.vn cho mục đích cá nhân hoặc sử dụng bởi thực thể
              kinh doanh mà Bạn đại diện. Nếu Bạn sử dụng Dịch vụ của Website
              Metric.vn để nhập các sản phẩm với mục đích bán lại, Bạn đồng ý tuân
              thủ các điều khoản, quy định của luật pháp để bán lại. Bạn không được
              rao bán, bán lại, phân phối, tạo dữ liệu hoặc khai thác thương mại nội
              dung trên Website hoặc thông tin được tạo ra bằng cách sử dụng Website
              dưới bất kỳ hình thức nào.
            </p>
            <p id="6fa50580-5dd9-4cd2-a30c-06fee6697286" class="">
              <strong>8. Nội dung độc hại:</strong> Chúng tôi thực hiện mọi nỗ lực
              hợp lý để loại bỏ virus và các nội dung độc hại khác từ các trang web,
              nhưng không bảo đảm hoàn toàn về việc này. Hãy sử dụng các biện pháp
              bảo vệ thích hợp trước khi tải thông tin từ trang web Website
              Metric.vn
            </p>
            <p id="18a7bc25-d934-42a8-88da-cc0eccfee100" class="">
              <strong>9. Sở hữu trí tuệ:</strong> Trang web Website Metric.vn, giao
              diện người dùng, nội dung, Công cụ tính phí vận chuyển và tất cả các
              phần mềm, bí quyết, phương pháp và quy trình liên quan là tài sản độc
              quyền của Website Metric.vn hoặc các nhà cung cấp bên thứ 3, được bảo
              vệ bởi Luật bản quyền, thương hiệu, bí mật thương mại và các luật về
              Sở hữu trí tuệ khác. Phần mềm, dữ liệu được hình thành từ tất cả phần
              mềm và tài liệu hiện hữu trên Website được Website Metric.vn cấp phép
              sử dụng trên cơ sở không độc quyền và có hạn chế.
            </p>
            <p id="c1e4a9a9-0a34-41b2-8c10-427f02c6393e" class="">
              9.1. Bạn không được sao chép, biên dịch ngược hoặc tạo ra các Sản phẩm
              phái sinh từ Website Website Metric.vn
            </p>
            <p id="e4536d1d-fd04-43cd-8f32-f97b0ae1a514" class="">
              9.2. Bạn không được sử dụng các thẻ meta/“văn bản ẩn” kết hợp tên hoặc
              nhãn hiệu của Website Metric.vn, trừ khi chúng tôi đồng ý trước bằng
              văn bản.
            </p>
            <p id="583be56e-5ee8-4231-ac98-10b8f6081c33" class="">
              9.3. Khi sử dụng Website, Bạn có thể in, lưu trữ điện tử một phần hoặc
              tất cả trang web, màn hình, nhưng chỉ cho mục đích sử dụng cá nhân.
            </p>
            <p id="11942a21-22b3-45a1-a3d8-240b29ff39d0" class="">
              9.4. Bạn không được hiển thị các thông tin từ Website Metric.vn để sử
              dụng trên các Website hoặc dịch vụ khác mà không có sự cho phép trước
              bằng văn bản của chúng tôi.
            </p>
            <p id="cf6e76d9-4d50-4df8-9e95-753eae0f0e44" class="">
              9.5. Bạn không được đặt một siêu liên kết (hyperlink) đến Website
              Metric.vn trên trang web của Bạn, trừ khi theo cách thức mà chúng tôi
              chỉ định.
            </p>
            <p id="157523af-4e02-4a75-b199-bfae647c3094" class="">
              <strong>10. Sự vi phạm, hàng giả:</strong>
            </p>
            <p id="0f4975f9-f6f4-4c83-9431-2363e5e72cd0" class="">
              10.1. Chúng tôi tôn trọng quyền sở hữu trí tuệ của người khác và nỗ
              lực để không công bố bất cứ tài liệu nào thuộc bản quyền, nhãn hiệu
              bên thứ 3 hoặc các bảo vệ pháp lý khác. Nếu Bạn tin rằng bất cứ tài
              liệu nào trên Website Website Metric.vn là vi phạm các quyền sở hữu
              trí tuệ của Bạn, vui lòng thông báo cho chúng tôi bằng cách gửi một
              đơn Khiếu nại vi phạm.
            </p>
            <p id="f6eb0636-2a90-49ab-b725-1230065d9096" class="">
              10.2. Website Metric.vn coi trọng những vấn đề nghiêm trọng gây ra bởi
              Quảng cáo và Bán hàng giả (hàng nhái, hàng giả mạo) trên Internet.
              Hàng giả mạo là những mặt hàng có logo và nhãn hiệu nhái theo các nhãn
              hiệu nổi tiếng, thường được bán với mức giá thấp hơn so với hàng chính
              hãng. Chúng đảm bảo rằng 100% các sản phẩm được quảng cáo và bán trên
              trang web của Website Metric.vn là hàng chính hãng.
            </p>
            <p id="e8a6822a-aab2-4558-b59d-30221f48a0fa" class="">
              <strong>11. Từ chối bảo đảm:</strong> Bạn chấp nhận rủi ro khi sử dụng
              Website và thừa nhận rằng các sản phẩm trên Website Website Metric.vn
              là được cung cấp trên nên tảng “có sẵn”. Chúng tôi cố gắng cung cấp
              thông tin chính xác và cập nhật thông qua Website Website Metric.vn.
              Website Metric.vn không chịu trách nhiệm về việc chậm trễ, gián đoạn
              hoặc không thể truy cập trang web và thực hiện giao dịch của Bạn do
              nhà cung cấp dịch vụ Internet/mạng thông tin viễn thông không đáng tin
              cậy, phầm mềm, thiết bị Bạn sử dụng cho việc truy cập không tương
              thích hoặc bị hỏng hóc, hoặc các nguyên nhân khác ngoài tầm kiểm soát
              của Website Metric.vn.
            </p>
            <p id="a5514aac-a243-4890-b7ce-05d89655c3f3" class="">
              <strong>12. Giới hạn trách nhiệm:</strong> Trong mọi trường hợp,
              Website Metric.vn không chịu trách nhiệm cho bất kỳ thiệt hại gián
              tiếp, đặc biệt, ngẫu nhiên, có tính chất trừng phạt hay cảnh cáo (bao
              gồm nhưng không giới hạn tổn thất kinh doanh, giảm doanh thu hay lợi
              nhuận, mất uy tín, cơ hội, gián đoạn kinh doanh hoặc mất thông tin)
              phát sinh từ việc sử dụng hoặc không thể sử dụng tài khoản hay Website
              Metric.vn của Bạn, thậm chí nếu ngay cả khi chúng tôi nhận được thông
              báo về khả năng xảy ra những thiệt hại đó.
            </p>
            <p id="449883da-a5b9-4f97-8824-7a4fb7697c49" class="">
              <strong>13. Thông báo:</strong> Các thông báo gửi đến số điện thoại
              Bạn cung cấp khi đặt hang thành công, tin tức và/hoặc thông tin được
              đăng tải trên Website là phương thức chính mà chúng tôi dùng để thông
              tin đến Bạn. Bạn cũng có thể gửi thông báo hoặc thông tin khác tới
              Website Metric.vn thông qua email hoặc Gọi điện thông qua thông tin ở
              phần Liên hệ.
            </p>
            <p id="23dcdb0f-3bf4-41b3-8ee3-bcbcfe51e1b3" class="">
              <strong
                >14. Sửa đổi các điều khoản dịch vụ của Website Metric.vn</strong
              >: Chúng tôi có thể sửa đổi các điều khoản dịch vụ này vào bất cứ lúc
              nào mà không cần thông báo. Việc Bạn tiếp tục sử dụng WEBSITE
              METRIC.VN sau những điều chỉnh đó xuất hiện sẽ được xem như Bạn đã
              đồng ý với những điều khoản đã được sửa đổi.
            </p>
            <p id="7479ce0a-882d-4627-b676-4bf230957ad0" class="">
              <strong
                >16. Giao dịch mua hàng chỉ giữa Bạn và Website Metric.vn:</strong
              >
              Website Metric.vn là bên trực tiếp bán sản phẩm cho bạn. Website
              Metric.vn đại diện, bảo đảm hay bảo hành liên quan đến chất lượng và
              sự phù hợp của các sản phẩm Bạn đặt hàng.
            </p>
            <p id="aaa3651f-9525-4485-b454-e6d7b5adbe3d" class="">
              <strong>17. Giao hàng:</strong>
            </p>
            <p id="88586a07-21a5-42f4-b555-f9ea4e90c788" class="">
              Website Metric.vn sẽ nỗ lực hợp lý để giao hàng đến cho Bạn theo lịch
              trình giao hàng của công ty vận chuyển, tuy nhiên điều này không được
              đảm bảo và không hình thành một phần của thỏa thuận giữa Website
              Metric.vn và Bạn. Website Metric.vn không chịu trách nhiệm với bất kỳ
              hư hại hay tổn thất gây ra bởi sự chậm trễ của công ty vận chuyển. .
            </p>
            <p id="5e1955d7-173f-4e0b-af4d-5df24cdacdd2" class="">
              <strong>18. Mất mát hoặc thiệt hại trong vận chuyển hàng:</strong>
              Trong trường hợp mất mát hoặc thiệt hại tất cả hoặc một phần của đơn
              hàng, Metric.vn sẽ hoàn tiền phí vận chuyển đối với kiện hàng bị ảnh
              hưởng (và giá trị hàng hóa nếu kiện hàng được bảo hiểm) vào tài khoản
              ngân hàng của bạn.
            </p>
            <p id="85666dc8-2b4c-4f44-8dc1-5c899eae1826" class="">
              <strong>20. Tạo khiếu nại:</strong> Bất cứ khiếu nại về mất mát hoặc
              tổn thất dịch vụ phải được trình lên Website Metric.vn trong vòng 3
              ngày kể từ ngày kiện hàng được giao đến cho Bạn. Nếu không, chúng tôi
              không chịu bất cứ trách nhiệm nào với các khiếu nại của Bạn. Khiếu nại
              phải được thực hiện bằng việc tạo yêu cầu khiếu nại trên hệ thống, gửi
              kèm với các giấy tờ hỗ trợ, ảnh hàng bị hư hỏng và Hóa đơn có liên
              quan cho Sản phẩm đó.
            </p>
            <p id="7b7d1dcc-0137-4eda-b4ac-24586687c65c" class="">
              <strong>21. Hoàn trả hoặc đổi trả hàng hóa:</strong> Nếu sau khi hàng
              được giao, Bạn muốn hoàn trả hàng, Website Metric.vn sẽ hỗ trợ bạn sớm
              nhất có thể cho việc hoàn trả, hoàn tiền hoặc đổi trả sản phẩm theo
              Chính sách đổi trả và hoàn tiền của chúng tôi.
            </p>
            <p id="e53a6d97-6690-49d0-90c4-e2e11f9a94ae" class="">
              <strong>22. Thay đổi dịch vụ Website Metric.vn:</strong> Chúng tôi có
              thể sửa đổi hoặc chấm dứt dịch vụ của chúng tôi vào bất cứ lúc nào
              theo thông báo hợp lý, kể cả tạm thời hay vĩnh viễn, mà không chịu
              trách nhiệm với Bạn ngoại trừ việc hoàn thành xử lý đơn hàng của Bạn
              được đặt trước ngày dịch vụ bị chấm dứt.
            </p>
            <p id="29aa2273-cd2e-4015-96b2-fb577896b71c" class="">
              <strong>23. Chấm dứt:</strong> Bạn có thể chấm dứt thỏa thuận này và
              việc sử dụng tài khoản Website Metric.vn của Bạn vào bất cứ lúc nào.
            </p>
            <p id="df857e36-fe4b-4e35-b83f-d4532707d1d6" class="">
              <strong>24. Các tình huống ngoài kiểm soát của chúng tôi:</strong>
              Website Metric.vn không chịu trách nhiệm cho bất cứ tổn thất hay mất
              mát phát sinh trong những tình huống ngoài kiểm soát của chúng tôi;
              bao gồm nhưng không giới hạn: Thảm họa thiên nhiên, ví dụ hỏa hoạn,
              động đất, bão lũ,…; tai nạn máy bay hoặc cấm vận; chiến tranh, bạo
              động dân sự hoặc đình công, dịch bệnh, mất điện, gián đoạn thông tin
              liên lạc hoặc các tiện ích khác, hành động hoặc không hành động của
              chính phủ, các hình ảnh điện tử hoặc ảnh chụp, dữ liệu và các bản ghi
              bị xóa hoặc bị hỏng do nhiễu điện hoặc từ trường.
            </p>
            <p id="37028e81-fb80-46bf-b1bc-a59f53d82cb8" class="">
              <strong>25. Bồi thường:</strong> Bạn đồng ý bồi thường, bảo vệ và giữ
              cho Website Metric.vn và các cố đông, nhân viên, giám đốc, công ty
              liên kết và các đại lý khỏi các khiếu nại, mất mát, tổn thất, trách
              nhiệm và chi phí (bao gồm phí luật sư hợp lý) phát sinh từ việc sử
              dụng Website và dịch vụ Website Metric.vn của Bạn , bao gồm nhưng
              không giới hạn các hành động cẩu thả hoặc thiếu sót của Bạn, bất cứ sự
              sai lệch Bạn gây ra liên quan đến việc sử dụng dịch vụ Website
              Metric.vn và việc Bạn không tuân thủ các điều khoản này.
            </p>
            <p id="cb2829cb-f0c1-407d-b3df-7b3286d94deb" class="">
              <strong>26. Toàn bộ thỏa thuận:</strong> Các điều khoản này (hoặc bản
              sửa đổi theo từng giai đoạn) hình thành toàn bộ thỏa thuận giữa Bạn và
              Website Metric.vn cho việc sử dụng Website Metric.vn, việc sử dụng
              thông tin của Bạn và điều khoản của dịch vụ Website Metric.vn. Bất cứ
              thông báo bằng văn bản hoặc truyền miệng đều bị bỏ qua và không có
              hiệu lực.
            </p>
            <p id="7adc5266-8e35-450f-934d-3f3007be899e" class="">
              <strong>27. Vô hiệu một phần:</strong> Nếu bất cứ điều khoản của thỏa
              thuận này được một tòa án hoặc cơ quan có thẩm quyền đánh giá là bất
              hợp pháp, không hợp lệ hoặc không thể thi hành, điều khoản đó sẽ được
              coi là đã bị xóa mà không ảnh hưởng đến bất kỳ điều khoản nào khác
              trong thỏa thuận này.
            </p>
            <p id="07f37fa6-0b08-4b70-ac42-8cf781da31a9" class="">
              <strong>28. Từ bỏ:</strong> Nếu Bạn hoặc Website Metric.vn không thực
              thi bất kỳ điều khoản nào của thỏa thuận này, hoặc không thực hiện bất
              kỳ quyền vào bất cứ lúc nào thì sẽ không được coi là từ bỏ.
            </p>
            <p id="f6e15faa-ef08-44aa-a331-4261cc967eae" class="">
              <strong>29. Các đề mục:</strong> Các đề mục là để thuận tiện tham khảo
              và không hình thành một phần của thỏa thuận này.
            </p>
            <p id="732aa5f0-49b5-4328-8e41-c03ffb1a9cb7" class="">
              <strong>30. Luật pháp điều chỉnh:</strong> Thỏa thuận này được điều
              chỉnh bởi pháp luật Việt Nam, không xung đột với các nguyên tắc của
              pháp luật.
            </p>
          </div>
        </article>
      </div>
    </LayoutArticle>
    <BeeFooter/>
  </div>
</template>

<script>
import BeeHeader from "@/components/partial/header/BeeHeader";
import BeeFooter from "@/components/partial/footer/BeeFooter";
import LayoutArticle from "@/layout/LayoutArticle";
import MobileHeader from "@/components/partial/header/MobileHeader";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

export default {
  name: "OrderPolicyView",
  components: {
    BeeHeader,
    BeeFooter,
    LayoutArticle,
    MobileHeader
  },
  mounted() {
    this.$router.push({ name: "Home" });
  },
  mixins: [
    mixinStoreGetterGlobal
  ]
}
</script>

<style scoped>

/* cspell:disable-file */
/* webkit printing magic: print all background colors */
html {
  -webkit-print-color-adjust: exact;
}
* {
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
}

html,
body {
  margin: 0;
  padding: 0;
}
@media only screen {
  body {
    margin: 2em auto;
    max-width: 900px;
    color: rgb(55, 53, 47);
  }
}

body {
  line-height: 1.5;
  white-space: pre-wrap;
}

a,
a.visited {
  color: inherit;
  text-decoration: underline;
}

.pdf-relative-link-path {
  font-size: 80%;
  color: #444;
}

h1,
h2,
h3 {
  letter-spacing: -0.01em;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.75em;
}

.mobile-view .page-title {
  font-size: 1rem;
  font-weight: 600;
}
h1 {
  font-size: 1.875rem;
  margin-top: 1.875rem;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  margin-top: 1.25rem;
}

.source {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1.5em;
  word-break: break-all;
}

.callout {
  border-radius: 3px;
  padding: 1rem;
}

figure {
  margin: 1.25em 0;
  page-break-inside: avoid;
}

figcaption {
  opacity: 0.5;
  font-size: 85%;
  margin-top: 0.5em;
}

mark {
  background-color: transparent;
}

.indented {
  padding-left: 1.5em;
}

hr {
  background: transparent;
  display: block;
  width: 100%;
  height: 1px;
  visibility: visible;
  border: none;
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

img {
  max-width: 100%;
}

@media only print {
  img {
    max-height: 100vh;
    object-fit: contain;
  }
}

@page {
  margin: 1in;
}

.collection-content {
  font-size: 0.875rem;
}

.column-list {
  display: flex;
  justify-content: space-between;
}

.column {
  padding: 0 1em;
}

.column:first-child {
  padding-left: 0;
}

.column:last-child {
  padding-right: 0;
}

.table_of_contents-item {
  display: block;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.125rem;
}

.table_of_contents-indent-1 {
  margin-left: 1.5rem;
}

.table_of_contents-indent-2 {
  margin-left: 3rem;
}

.table_of_contents-indent-3 {
  margin-left: 4.5rem;
}

.table_of_contents-link {
  text-decoration: none;
  opacity: 0.7;
  border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}

table,
th,
td {
  border: 1px solid rgba(55, 53, 47, 0.09);
  border-collapse: collapse;
}

table {
  border-left: none;
  border-right: none;
}

th,
td {
  font-weight: normal;
  padding: 0.25em 0.5em;
  line-height: 1.5;
  min-height: 1.5em;
  text-align: left;
}

th {
  color: rgba(55, 53, 47, 0.6);
}

ol,
ul {
  margin: 0;
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
}

li > ol:first-child,
li > ul:first-child {
  margin-block-start: 0.6em;
}

ul > li {
  list-style: disc;
}

ul.to-do-list {
  text-indent: -1.7em;
}

ul.to-do-list > li {
  list-style: none;
}

.to-do-children-checked {
  text-decoration: line-through;
  opacity: 0.375;
}

ul.toggle > li {
  list-style: none;
}

ul {
  padding-inline-start: 1.7em;
}

ul > li {
  padding-left: 0.1em;
}

ol {
  padding-inline-start: 1.6em;
}

ol > li {
  padding-left: 0.2em;
}

.mono ol {
  padding-inline-start: 2em;
}

.mono ol > li {
  text-indent: -0.4em;
}

.toggle {
  padding-inline-start: 0em;
  list-style-type: none;
}

/* Indent toggle children */
.toggle > li > details {
  padding-left: 1.7em;
}

.toggle > li > details > summary {
  margin-left: -1.1em;
}

.selected-value {
  display: inline-block;
  padding: 0 0.5em;
  background: rgba(206, 205, 202, 0.5);
  border-radius: 3px;
  margin-right: 0.5em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  white-space: nowrap;
}

.collection-title {
  display: inline-block;
  margin-right: 1em;
}

time {
  opacity: 0.5;
}

.icon {
  display: inline-block;
  max-width: 1.2em;
  max-height: 1.2em;
  text-decoration: none;
  vertical-align: text-bottom;
  margin-right: 0.5em;
}

img.icon {
  border-radius: 3px;
}

.user-icon {
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  margin-right: 0.5rem;
}

.user-icon-inner {
  font-size: 0.8em;
}

.text-icon {
  border: 1px solid #000;
  text-align: center;
}

.page-cover-image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 30vh;
}

.page-header-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.page-header-icon-with-cover {
  margin-top: -0.72em;
  margin-left: 0.07em;
}

.page-header-icon img {
  border-radius: 3px;
}

.link-to-page {
  margin: 1em 0;
  padding: 0;
  border: none;
  font-weight: 500;
}

p > .user {
  opacity: 0.5;
}

td > .user,
td > time {
  white-space: nowrap;
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin-right: 0.6em;
  vertical-align: middle;
}

p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.image {
  border: none;
  margin: 1.5em 0;
  padding: 0;
  border-radius: 0;
  text-align: center;
}

.code,
code {
  background: rgba(135, 131, 120, 0.15);
  border-radius: 3px;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 85%;
  tab-size: 2;
}

code {
  color: #eb5757;
}

.code {
  padding: 1.5em 1em;
}

.code-wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.code > code {
  background: none;
  padding: 0;
  font-size: 100%;
  color: inherit;
}

blockquote {
  font-size: 1.25em;
  margin: 1em 0;
  padding-left: 1em;
  border-left: 3px solid rgb(55, 53, 47);
}

.bookmark {
  text-decoration: none;
  max-height: 8em;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: stretch;
}

.bookmark-title {
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.75em;
  white-space: nowrap;
}

.bookmark-text {
  display: flex;
  flex-direction: column;
}

.bookmark-info {
  flex: 4 1 180px;
  padding: 12px 14px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bookmark-image {
  width: 33%;
  flex: 1 1 180px;
  display: block;
  position: relative;
  object-fit: cover;
  border-radius: 1px;
}

.bookmark-description {
  color: rgba(55, 53, 47, 0.6);
  font-size: 0.75em;
  overflow: hidden;
  max-height: 4.5em;
  word-break: break-word;
}

.bookmark-href {
  font-size: 0.75em;
  margin-top: 0.25em;
}

.sans { font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"; }
.code { font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace; }
.serif { font-family: Lyon-Text, Georgia, ui-serif, serif; }
.mono { font-family: iawriter-mono, Nitti, Menlo, Courier, monospace; }
.pdf .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK JP'; }
.pdf:lang(zh-CN) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK SC'; }
.pdf:lang(zh-TW) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK TC'; }
.pdf:lang(ko-KR) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK KR'; }
.pdf .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.pdf .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK JP'; }
.pdf:lang(zh-CN) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK SC'; }
.pdf:lang(zh-TW) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK TC'; }
.pdf:lang(ko-KR) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK KR'; }
.pdf .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.highlight-default {
}
.highlight-gray {
  color: rgb(155,154,151);
}
.highlight-brown {
  color: rgb(100,71,58);
}
.highlight-orange {
  color: rgb(217,115,13);
}
.highlight-yellow {
  color: rgb(223,171,1);
}
.highlight-teal {
  color: rgb(15,123,108);
}
.highlight-blue {
  color: rgb(11,110,153);
}
.highlight-purple {
  color: rgb(105,64,165);
}
.highlight-pink {
  color: rgb(173,26,114);
}
.highlight-red {
  color: rgb(224,62,62);
}
.highlight-gray_background {
  background: rgb(235,236,237);
}
.highlight-brown_background {
  background: rgb(233,229,227);
}
.highlight-orange_background {
  background: rgb(250,235,221);
}
.highlight-yellow_background {
  background: rgb(251,243,219);
}
.highlight-teal_background {
  background: rgb(221,237,234);
}
.highlight-blue_background {
  background: rgb(221,235,241);
}
.highlight-purple_background {
  background: rgb(234,228,242);
}
.highlight-pink_background {
  background: rgb(244,223,235);
}
.highlight-red_background {
  background: rgb(251,228,228);
}
.block-color-default {
  color: inherit;
  fill: inherit;
}
.block-color-gray {
  color: rgba(55, 53, 47, 0.6);
  fill: rgba(55, 53, 47, 0.6);
}
.block-color-brown {
  color: rgb(100,71,58);
  fill: rgb(100,71,58);
}
.block-color-orange {
  color: rgb(217,115,13);
  fill: rgb(217,115,13);
}
.block-color-yellow {
  color: rgb(223,171,1);
  fill: rgb(223,171,1);
}
.block-color-teal {
  color: rgb(15,123,108);
  fill: rgb(15,123,108);
}
.block-color-blue {
  color: rgb(11,110,153);
  fill: rgb(11,110,153);
}
.block-color-purple {
  color: rgb(105,64,165);
  fill: rgb(105,64,165);
}
.block-color-pink {
  color: rgb(173,26,114);
  fill: rgb(173,26,114);
}
.block-color-red {
  color: rgb(224,62,62);
  fill: rgb(224,62,62);
}
.block-color-gray_background {
  background: rgb(235,236,237);
}
.block-color-brown_background {
  background: rgb(233,229,227);
}
.block-color-orange_background {
  background: rgb(250,235,221);
}
.block-color-yellow_background {
  background: rgb(251,243,219);
}
.block-color-teal_background {
  background: rgb(221,237,234);
}
.block-color-blue_background {
  background: rgb(221,235,241);
}
.block-color-purple_background {
  background: rgb(234,228,242);
}
.block-color-pink_background {
  background: rgb(244,223,235);
}
.block-color-red_background {
  background: rgb(251,228,228);
}
.select-value-color-default { background-color: rgba(206,205,202,0.5); }
.select-value-color-gray { background-color: rgba(155,154,151, 0.4); }
.select-value-color-brown { background-color: rgba(140,46,0,0.2); }
.select-value-color-orange { background-color: rgba(245,93,0,0.2); }
.select-value-color-yellow { background-color: rgba(233,168,0,0.2); }
.select-value-color-green { background-color: rgba(0,135,107,0.2); }
.select-value-color-blue { background-color: rgba(0,120,223,0.2); }
.select-value-color-purple { background-color: rgba(103,36,222,0.2); }
.select-value-color-pink { background-color: rgba(221,0,129,0.2); }
.select-value-color-red { background-color: rgba(255,0,26,0.2); }

.checkbox {
  display: inline-flex;
  vertical-align: text-bottom;
  width: 16;
  height: 16;
  background-size: 16px;
  margin-left: 2px;
  margin-right: 5px;
}

.checkbox-on {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
}

.checkbox-off {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
}


</style>
